import type {FirestoreTimestamp} from '../types/firestore';
import {
  AnalyticsView,
  DistinctCollectionName,
  FieldPath,
  SessionState,
  SimpleCounterName,
} from '../types/session';
import {
  CustomType,
  DbProductVariantModel,
  ProductCustomizationImage,
  ProductCustomizationText,
  ProductCustomProperties,
} from './product';
import {DbUserModel} from './user';
import {
  DbCouponsDocModel,
  DbViewersDiscountModel,
  DiscountDataInDbSessionModel,
  DiscountTypes,
  SessionViewersDiscountModel,
  DiscountCalculationType,
} from '../types/discounts';
import {Docs} from './db-structure';

export {DiscountTypes, DbViewersDiscountModel, SessionViewersDiscountModel, DbCouponsDocModel};

export const RTDB_PER_SESSION = 1;
export const RTDB_PER_STORE = 2;
export const CURRENT_RTDB_VERSION = RTDB_PER_STORE;
export const ANALYTICS_BIGQUERY = 2;
export const CURRENT_ANALYTICS_VERSION = ANALYTICS_BIGQUERY;
/*
* Collections inside sessions collections with their doc types
* sessions/{sessionId}
* DbSessionModel{
    data{
        hostLiveData: DbSessionHostLiveDataModel {
            ProductLiveData: DbSessionHostLiveDataModel
        }
    }
    private/broadcastToken :DbSessionBroadcastTokenModel :
    sessionProducts/{productId} : DbSessionProductModel
    sessionProducts/{productId}/sessionProductVariants/{variantId} :DbSessionProductVariantModel
    sessionSeats/{id} : DbSessionSeatModel
    sessionEvents/{eventId} :DbSessionProductEventDataModel

}
*/

export type SessionFeature = 'affiliate' | 'age-restriction' | 'customized-popups';

export function isFeatureEnabled(sessionFeatures: SessionFeature[], feature: SessionFeature) {
  return sessionFeatures.includes(feature) ?? false;
}

export function setFeatureState(session: DbSessionModel, feature: SessionFeature, state: boolean) {
  session.sessionFeatures = state
    ? [...(session.sessionFeatures ?? []), feature]
    : session.sessionFeatures?.filter((i) => i !== feature) ?? [];
}
export type AgeRestriction = {
  header: string;
  description: string;
  consentText: string;
  ageConfirmationButtonText: string;
  underAgedButtonText: string;
  showDescription: boolean;
  showPopUp: boolean;
};
export type Popup = {
  id: string;
  header: string;
  description: string;
  btnText: string;
  inputTitles: [string];
  popupTiming: {
    entrance: boolean;
    ended: boolean;
    clickCheckout: boolean;
    minutesAfterJoined: number;
    minutesAfterSessionStarted: number;
  };
  showDescription: boolean;
  showInput: boolean;
  showPopUp: boolean;
  showButton: boolean;
};
export class DbSessionModel implements DiscountDataInDbSessionModel {
  id: string;
  storeId: string;
  description: string;
  shortDescription: string;
  coverImage?: string;
  hostUserId?: string;
  hostName: string;
  hostImage?: string;
  sessionURL?: string;
  startTime: FirestoreTimestamp;
  createDate: FirestoreTimestamp;
  endTime?: FirestoreTimestamp;
  hasEnded: boolean;
  isActive: boolean;
  viewers: number;
  likes: number;

  actionsRequireLogin: boolean = false;

  sessionFeatures: SessionFeature[] = [];
  sessionFeaturesData?: {ageRestriction?: AgeRestriction; customizedPopups?: [Popup]};
  recordedVideo?: RecordedVideo;
  isHorizontalView: boolean;
  // TODO: remove in version greater than 1.13.x
  userLink: never | undefined;

  //external video source
  externalVideoSource: string | undefined;
  externalVideoSourceMobile: string | undefined;
  isLandscapeSource: boolean | undefined;

  // Settings
  additionalViewersManipulation?: number;
  maxAllowedUsers?: number;
  minimumAllowedUsers?: number;
  entryFee?: number;
  entryFeeText?: string;

  rtdbInfo?: RtdbInfo;
  rtdbVersion?: number = CURRENT_RTDB_VERSION;
  analyticsVersion?: number = CURRENT_ANALYTICS_VERSION;

  liveDataBackup: DbSessionLiveDataModel | undefined = undefined;
  sessionType: boolean | null = null;
  postFeaturedItems?: featuredItemFieldSession[] = [];
  concurrentFeaturedItems?: number;

  hostVisitedSessions?: Array<string>;
  hostVisitedQualityCheck?: Array<{hostUserId: string; timestamp: string}>;

  talkingPoints?: string[];
  talkingPointsHistory?: Record<string, TalkingPointHistory>;

  constructor() {
    this.discountType = DiscountTypes.GeneralDiscount;
    this.viewersPercentageDiscounts = [];
    this.viewersItemDiscounts = [];
  }

  discountType: DiscountTypes;
  generalDiscount?: number;
  // This property is saved in order admin could change it in session management section
  generalDiscountCalculationType?: DiscountCalculationType;
  viewersPercentageDiscounts: DbViewersDiscountModel[];
  viewersPercentageDiscountsCalculationType?: DiscountCalculationType;
  viewersItemDiscounts: DbViewersDiscountModel[];
  viewersItemDiscountsCalculationType?: DiscountCalculationType;

  twilioChatId?: string;
  twilioChatSid?: string;
  useTwilioChat: boolean = false;
}

export interface Thumbnail {
  url: string;
  width: number;
  height: number;
}

export interface featuredItem {
  featuredProductId: string;
  featuredProductVariantId: string;
  featuredProductImageUrl: string;
}

// sessions/{sessionId}/private/broadcastToken
export class DbSessionBroadcastTokenModel {
  sessionId: string;
  broadcastToken: string;
  broadcastTokenUid: number;
  createAt: number;
}

// session/{sessionId}/sessionSeats/{id}
export class DbSessionSeatModel {
  id: string;
  seatNumber: number | null;
  sessionId: string;
  userId: string | null;
  ipAddress: string;
  createDate: FirestoreTimestamp;
  lastHeartbeat: FirestoreTimestamp;
  secondsOfView: number | undefined; // we do not have it on old seats
  broadcastToken: string | null;
  isReleased: boolean;
  isStatic: boolean;
  deposit: number | null;
  usedDeposit: number | null;

  // Data for paid seat
  paymentId?: string | null;
  broadcastTokenUid: number | null;
  updateDate?: FirestoreTimestamp;
  videoDuration: number | undefined; // added on 2024-04-23, undefined for seats before that date
  timestamp: number | null; // added on 2024-06-11 fix this bug https://terrific-force.monday.com/boards/6041659431/pulses/6917747764
}

export class DbSessionLiveDataModel {
  sessionId: string | null;
  viewers: number | null;
  maxViewers: number | null;
  featuredProductId: string | null;
  featuredProductVariantId: string | null;
  featuredProductImageUrl: string | null;
  sessionState: SessionState;
  currentPromoVideoLink: string | null;
  currentPromoVideoLength: number | null;
  currentPromoVideoStartTime: FirestoreTimestamp | null;
  likes: number | null;
  featuredItems?: featuredItem[];
  // TODO: remove in version greater than 1.13.x
  userLink: never | null;
  updatedAt: FirestoreTimestamp | null;

  redirectTo: string | null; //  Target URL - used to force redirect from session to a specific URL. only if doRedirect is true.
  doRedirect: boolean | null; //  If true, the session should redirect to the URL in redirectTo. this is an additional guard.

  constructor() {
    this.viewers = 0;
    this.maxViewers = 0;
    this.likes = 0;
    this.sessionState = SessionState.notStarted;
  }
}

export type DbShareTypeModel = {
  to: 'Mail' | 'Twitter' | 'Link' | 'WhatsApp' | 'Facebook';
};

export type DbEventsTypesModel =
  | DbShareTypeModel
  | 'purchased'
  | 'addToCart'
  | 'orderCreated'
  | 'startPayment'
  | 'viewed';

export type DbSessionEventDataModel = {
  event: DbEventsTypesModel;
  value: 1 | -1;
};

export class DbSessionProductEventDataModel {
  id: string;
  productId: string;
  variantId: string;
  who?: DbUserModel | DbSessionSeatModel;
  event: DbSessionEventDataModel;
  wasCreatedWhenFeatured: boolean;
  href: URL;
  timestamp: FirestoreTimestamp;
}

export class DbSessionProductLiveData {
  productId: string;
  variantId: string;
  type: DbEventsTypesModel;
  count: number;
  sum: number;
  lastEventId: string;
}

export class DbSessionHostLiveDataModel {
  sessionId: string;
  viewers: number;
  maxViewers: number;
  shares: number;
  baseLiveViewers: number;
  // #TODO subcollection for each product in session:
  //      productEvents [{who,what,isFeatured,fromWhere(componentName),timestamp}]
  //      1 in carts total + last event + sum event as {cartId, Owner Name, N items, createTime, updateTime}
  //      2 shared  total + last event {Who, to}: share event
  //      3 purchased [{cartId, orderId, Owner Name, N items, createTime, updateTime}: productCart]
}

export class DbSessionProductModel {
  sessionId: string = '';
  storeId: string = '';
  productId: string = '';

  availabilityDependency?: number;

  // Derived from the product
  name: string = '';
  shortDescription: string = '';
  mainImageUrl: string = '';
  imagesCount: number;
  price: number;
  compareAtPrice: number | null;
  shippingMethodIds: string[] = [];
  optionNames: string[] = [];
  customProperties?: ProductCustomProperties = {};

  externalId: string | null;
  externalUrl: string | null;
  integrationType?: string | null;

  // Customization
  isCustomized: boolean;
  customType: CustomType | null;
  customizationText: ProductCustomizationText | null;
  customizationImage: ProductCustomizationImage | null;
}

export class DbSessionPollProductModel {
  productId: string = '';
  name: string = '';
  mainImageUrl: string = '';
}

export class DbSessionProductVariantModel {
  sessionId: string;
  storeId: string;
  productId: string;
  variantId: string;

  externalId: string | null;
  externalUrl: string | null;

  stock: number;
  locked: number;

  // Derived from the variant
  optionValues: {[option: string]: string};
  imageUrls: string[];
  price: number;
  compareAtPrice: number | null;
  sku?: string;
  orderIndex: number;
}

export class SessionProduct {
  isNew: boolean;
  data: DbSessionProductModel;
  isLoadingVariants: boolean;
  variants: SessionProductVariant[];
}

export class SessionProductVariant {
  variant: DbProductVariantModel;
  sessionVariant?: DbSessionProductVariantModel;
  isChecked: boolean;
  stock: number;
  locked: number;
}

export const PollVoteOptions = ['1', '2', '3', '4'] as const;
export type PollVoteOption = (typeof PollVoteOptions)[number];
type PollVoteCountsRecord = Record<PollVoteOption, number>;
export type PollVoteCounts = Partial<PollVoteCountsRecord> &
  Required<Pick<PollVoteCountsRecord, '1' | '2'>>;

export class PollResults {
  votes: PollVoteCounts;
  total: number;
  lastVoters: string[]; // [string?, string?];
  percentage: PollVoteCounts;
  winners: PollVoteOption[];

  static emptyVoteCounts(answersCount: number): PollVoteCounts {
    const voteCounts: PollVoteCounts = {
      [PollVoteOptions[0]]: 0,
      [PollVoteOptions[1]]: 0,
    };
    for (let i = 2; i < answersCount; i++) {
      voteCounts[PollVoteOptions[i]] = 0;
    }
    return voteCounts;
  }

  static new(answersCount: number): PollResults {
    return {
      votes: PollResults.emptyVoteCounts(answersCount),
      total: 0,
      lastVoters: [],
      percentage: PollResults.emptyVoteCounts(answersCount),
      winners: [],
    };
  }
}

// session/{sessionId}/sessionPolls/{pollId}
export class DbSessionPollModel {
  id: string;
  sessionId: string;
  storeId: string;
  type: PollType;
  question: string;
  items: (DbSessionPollProductModel | null)[];
  itemIds: (string | null)[];
  options: string[];
  status: PollStatus;
  createDate: FirestoreTimestamp;
  activationDate?: FirestoreTimestamp;
  results?: PollResults;

  static answersCount(poll: DbSessionPollModel): number {
    return poll.type == PollType.ItemQuestion ? poll.items.length : poll.options.length;
  }
}

export enum PollType {
  ItemQuestion = 0,
  GeneralQuestion = 1,
}

export enum PollStatus {
  Inactive = 0,
  Active = 1,
  Locked = 2,
  Deleted = 3,
}

export const PollsVisibleToAdmin = [PollStatus.Inactive, PollStatus.Active, PollStatus.Locked];
export const PollsVisibleToUser = [PollStatus.Active, PollStatus.Locked];

export class DbSessionPollUserModel {
  id: string;
  userId: string;
  pollId: string;
  sessionId: string;
  storeId: string;
  isViewed: boolean;
  viewDate: FirestoreTimestamp | null;
  vote?: PollVoteOption | null;
  voteDate: FirestoreTimestamp | null;

  static new(
    pollId: string,
    userId: string,
    sessionId: string,
    storeId: string,
    vote: PollVoteOption,
    timestamp: FirestoreTimestamp
  ) {
    return {
      id: DbSessionPollUserModel.pollUserId(pollId, userId),
      pollId: pollId,
      userId: userId,
      sessionId: sessionId,
      storeId: storeId,
      viewDate: timestamp,
      isViewed: true,
      vote: vote,
      voteDate: timestamp,
    };
  }

  static pollUserId(pollId: string, userId: string) {
    return `${pollId}_${userId}`;
  }
}

export type DbParentMessage = {
  userName: string | null;
  messageText: string;
  messageId: string | null;
  parentId: string | undefined;
};

export class DbSessionMessageModel {
  id: string | null;
  sessionId: string;
  userId: string;
  userName: string | null;
  userPhoto: string | null;
  message: string;
  createDate: FirestoreTimestamp;
  trackId: string;
  isSpecialUser: boolean | null; //admin, host, etc
  parentMessage: DbParentMessage | null;
  parentMessageId: string | null;
}

export class DbSessionLikeModel {
  sessionId: string;
  userId: string;
  createDate: FirestoreTimestamp;
}

export class DbSessionViewModel {
  sessionId: string;
  userId: string;
  createDate: FirestoreTimestamp;
}

export class DbSessionReviewModel {
  sessionId: string;
  userId: string;
  score: DbSessionReviewScore;
  notes?: string;
}

export enum DbSessionReviewScore {
  Frustrating = 1,
  Boring = 2,
  Fine = 3,
  Terrific = 4,
}

// sessionHostInvites/{id}
export class DbSessionHostInviteModel {
  id?: string;
  sessionId: string;
  email: string;
  active: boolean;
  addedByUserId: string;
  inviteDate: FirestoreTimestamp;
}

// sessionReminders/{id}
export class DbSessionReminderModel {
  id?: string;
  userId: string;
  userEmail: string;
  sessionId: string;
  sessionCover?: string;
  sessionDescription: string;
  sessionStartDate: FirestoreTimestamp;
  hostImage?: string;
  hostName: string;
  storeLogo?: string;
  storeName?: string;
  createDate: FirestoreTimestamp;
  wasSent: boolean;
  addresseeName?: string | null;
  addresseePhone?: string | null;
  userName: string | null;
  addresseeCountryCode: string | null;
}

export class DbSessionPromoVideoModel {
  id: string;
  isActive: boolean;
  name: string;
  link: string;
  length: number;
  lengthAsString: string;
  thumbnail: Thumbnail;
  productId?: string;
  variantId?: string;
}

export class DbSessionRecordingResources {
  recordingToken: string;
  resourceId: string;
  recordingSid: string;
  recordingUid: string;
  videoData?: VideoData;
}

export interface VideoData {
  fileList:
    | string
    | {
        fileName: string;
        isPlayable: boolean;
        mixedAllUser: boolean;
        sliceStartTime: number;
        trackType: string;
        uid: 'string';
      }[];
  fileListMode?: string;
  uploadingStatus?: string;
}

export class RtdbInfo {
  // Google's fields
  // name: string;
  // project: string;
  databaseUrl: string;
  // type: string;
  state: 'DELETED' | 'DISABLED' | 'ACTIVE';
  // Custom fields
  lastUpdateToRules?: FirestoreTimestamp;
}

export class RecordedVideo {
  url?: string;
  urlOfAutoCombinedVideos?: string;
  urlOfManuallySelectedVideo?: string | null;
  urlOfManuallyUploadedVideo?: string;
}

export type DbSessionAnalyticsCountersDoc = {
  [AnalyticsView.Live]?: SimplifiedCounterForState;
  [AnalyticsView.PostSession]?: SimplifiedCounterForState;
  [AnalyticsView.Upcoming]?: SimplifiedCounterForState;
  [AnalyticsView.Total]?: SimplifiedCounterForState;
};

export type SimplifiedCounterForState = {
  [key in SimpleCounterName]?: key extends StartWith<
    key,
    DistinctCollectionName | FieldPath | 'eachItemCountInCarts'
  > // cases where the value is an object
    ? any
    : number | undefined;
};

type StartWith<T extends string, Start extends string> = T extends `${Start}${string}` ? T : never;

export class DbSessionAnalyticsCallModel {
  timestamp: FirestoreTimestamp;
}

export class DbSessionDataStatisticModel {
  id: typeof Docs.SESSION_STATISTIC_DOC_ID;
  storeId: string;
  sessionId: string;
  sessionStartTime: FirestoreTimestamp;
  videoDuration: number;
  viewersCount: number;
}

export interface featuredItemFieldSession {
  productId: string;
  variantId: string;
  videoStartTime: number;
  videoEndTime?: number;
  timestamp: FirestoreTimestamp;
}

export interface TalkingPointScore {
  score: number;
  timestamp: Date;
  transcriptionId: string;
}

export interface TalkingPointHistory {
  scores: TalkingPointScore[];
}
